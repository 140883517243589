// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/YurukaStd.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/ShangShouFangTangTi.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  padding: 0;\n  background-color: rgb(87, 73, 91);\n}\n\n@font-face {\n  font-family: 'YurukaStd';\n  src: local('YurukaStd'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\n}\n\n@font-face {\n  font-family: 'SSFangTangTi';\n  src: local('SSFangTangTi'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2');\n}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,iCAAiC;AACnC;;AAEA;EACE,wBAAwB;EACxB,gFAAqE;AACvE;;AAEA;EACE,2BAA2B;EAC3B,mFAAkF;AACpF","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  background-color: rgb(87, 73, 91);\n}\n\n@font-face {\n  font-family: 'YurukaStd';\n  src: local('YurukaStd'), url(./fonts/YurukaStd.woff2) format('woff2');\n}\n\n@font-face {\n  font-family: 'SSFangTangTi';\n  src: local('SSFangTangTi'), url(./fonts/ShangShouFangTangTi.woff2) format('woff2');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
